
// Settings
const triggerResizeMinTime = 100;
const triggerResizeMaxTime = 500;

// Declare windowresize event, triggered by mouse move and scroll
const windowresizeEvent = new CustomEvent("windowresize", {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false,
});

// Use the event :
//document.addEventListener('windowresize', (e) => {
    // Do something
//}, false);


// On resize, check if should trigger custom resize event
var last_resize_change_time = performance.now();
var last_resize_trigger = performance.now();
var trigger_resize_event;
jQuery(window).on('resize', function () { 
    // If resizing, cancel previous timeout for trigger
    clearTimeout(trigger_resize_event);

    // If resizing since too long, trigger event
    if(performance.now() - last_resize_trigger > triggerResizeMaxTime) {
        document.dispatchEvent(windowresizeEvent);
        last_resize_trigger = performance.now();
    }

    // If stopped resizing, trigger event
    trigger_resize_event = setTimeout(() => {
        document.dispatchEvent(windowresizeEvent);
        last_resize_trigger = performance.now();
    }, triggerResizeMinTime);

    // Update time
    last_resize_change_time = performance.now();
});