
// Settings
const maxCursorMoveRate = 0; // X updates / second
const maxScrollRate = 10; // X updates / second

// Declare cursormove event, triggered by mouse move and scroll
const cursormoveEvent = new CustomEvent("cursormove", {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false,
});
const windowScrollEvent = new CustomEvent("windowScroll", {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false,
});

// Use the event :
//document.addEventListener('cursormove', (e) => {
    // Do something
    //console.log('cursormove tick');
//}, false);


// Init vars
var xMouse = window.innerWidth / 5;
var yMouse = window.innerHeight / 2;
var current_scroll = jQuery(window).scrollTop();
var xPos = xMouse;
var yPos = yMouse + current_scroll;
var block_trigger = false;
var block_scroll_trigger = false;


// On mouse move, update xPos and yPos
window.addEventListener("mousemove", event => { 
        
    // Update mouse position
    xMouse = event.x;
    yMouse = event.y;

    // If not blocked by rate limit
    if (!block_trigger) {
        
        // Dispatch "cusormove" event
        document.dispatchEvent(cursormoveEvent);

        // If rate limit set, manage it
        if(maxCursorMoveRate > 0) {
            block_trigger = true;
            setTimeout(function () {
                block_trigger = false;
            }, 1000 / maxCursorMoveRate);
        }
    } 
});

// Use ScrollSmoother Y value to update mouse yPos, comment to use vanilla scroll values (from track_mouse_position.js)
var current_scroll = 0;
function smoothScrolled(e) {
    if (typeof smoother !== 'undefined') {
        current_scroll = smoother.scrollTop(); 
    } else {
        current_scroll = 0;
    }
    //console.log(document.elementFromPoint(xPos, yPos));

    if (!block_trigger) {
        document.dispatchEvent(cursormoveEvent);
        if(maxCursorMoveRate > 0) {
            block_trigger = true;
            setTimeout(function () {
                block_trigger = false;
            }, 1000 / maxCursorMoveRate);
        }
    } 

    if (!block_scroll_trigger) {
        document.dispatchEvent(windowScrollEvent);
        if(maxScrollRate > 0) {
            block_scroll_trigger = true;
            setTimeout(function () {
                block_scroll_trigger = false;
            }, 1000 / maxScrollRate);
        }
    } 
    
}

// Update x y Pos on GSAP tick
gsap.ticker.add(() => {
    xPos += (xMouse - xPos);
    yPos += (yMouse + current_scroll - yPos);
    //console.log('Pos : ( '+xPos+' ; '+yPos+' )');
});

//jQuery(window).scroll(function (e) { 
    //document.dispatchEvent(windowScrollEvent);
    
    // current_scroll = jQuery(window).scrollTop();
    
    // if (!block_trigger) {
    //     document.dispatchEvent(cursormoveEvent);
    //     if(maxCursorMoveRate > 0) {
    //         block_trigger = true;
    //         setTimeout(function () {
    //             block_trigger = false;
    //         }, 1000 / maxCursorMoveRate);
    //     }
    // } 
//});







// If smoothScrolled defined in gsap_load.js, let it manage scroll diff
// if(typeof smoothScrolled !== "function") {
        
//     // On scroll, add scroll diff to yPos
//     jQuery(window).scroll(function (e) { 
//         ///console.log('scroll');
//         //if(!lock_scroll) {
//             //console.log('scroll unlocked');
//             document.dispatchEvent(windowScrollEvent);
//             if (!block_trigger) {

//                 if (lastScrolled != jQuery(window).scrollTop()) {
//                     yPos -= lastScrolled;
//                     lastScrolled = jQuery(window).scrollTop();
//                     //console.log('Current scroll position : '+lastScrolled);
//                     yPos += lastScrolled;
//                 }
//                 document.dispatchEvent(cursormoveEvent);

                

//                 block_trigger = true;
//                 setTimeout(function () {
//                     block_trigger = false;
//                 }, 1000 / maxCursorMoveRate);
//             }
//         //}
//     });

//     // scroll_diff = 0;
//     // function smoothScrolled(e) {
//     //     if(!lock_scroll) {
//     //         real_scroll = jQuery('#smooth-content').css('transform');
//     //         real_scroll = real_scroll.replace('matrix(1, 0, 0, 1, 0, -', '');
//     //         real_scroll = real_scroll.replace('matrix(1, 0, 0, 1, 0, ', '');
//     //         real_scroll = real_scroll.replace(')', '');
//     //         real_scroll = parseInt(real_scroll);

//     //         scroll_diff = lastScrolled - real_scroll; 

//     //         //console.log('lastScrolled : '+lastScrolled+', real_scroll : '+real_scroll+', scroll_diff : '+scroll_diff);
//     //     }
//     // }
// }

// On resize, compute new offsets and centers
//jQuery(window).resize(function () { 

//});