
function getCookie(cookie_name) {
    var cookies = document.cookie.split('; ')
    var value = cookies
        .find(c => c.startsWith(cookie_name+"="))
        ?.split('=')[1]
    if (value !== undefined) {
        return decodeURIComponent(value);
    } else {
        return false;
    }
}

function setCookie(cookie_name, value) {
    const date = new Date()
    date.setDate(date.getDate() + 365)
    document.cookie = `${cookie_name}=${value}; expires=${date.toUTCString()}; path=/`
}